import {
  AppLayout,
  BreadcrumbGroup,
  Button,
  ContentLayout,
  Header,
  SideNavigation,
  SpaceBetween,
  Form,
  Container,
  FormField,
  Input,
  TopNavigation,
  Flashbar
} from '@cloudscape-design/components';
import { Route, Routes, Navigate, BrowserRouter as Router } from 'react-router-dom';
import { useState } from 'react';
import { fetchAuthSession } from 'aws-amplify/auth';
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import logo from './assets/logo512.png';
import '@cloudscape-design/global-styles/index.css';
import { v4 as uuidv4 } from 'uuid';

interface FormData {
  firstName?: string; // Optional firstName property
  lastName?: string; // Optional lastName property
  employeeId?: string; // Optional employeeId property
  department?: string; // Optional department property
}

const App = () => {
  const [inputFirstName, setInputFirstNameValue] = useState('');
  const [inputLastName, setInputLastNameValue] = useState('');
  const [inputEmployeeId, setInputEmployeeIdValue] = useState('');
  const [inputDepartment, setInputDepartmentValue] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccessFlashbar, setShowSuccessFlashbar] = useState(false);
  const [showErrorFlashbar, setShowErrorFlashbar] = useState(false);
  const [showSubmitFlashbar, setShowSubmitFlashbar] = useState(false);




  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setShowSubmitFlashbar(true)
    setIsSubmitting(true); // Set isSubmitting to true before the async operations
    e.preventDefault();

    // Generate a unique bucket key value
    const bucketKey = uuidv4();

    const formData: FormData = {

    };

    if (inputFirstName !== '') {
      formData['firstName'] = inputFirstName
      setInputFirstNameValue('')
    }

    if (inputLastName !== '') {
      formData['lastName'] = inputLastName
      setInputLastNameValue('')
    }

    if (inputEmployeeId !== '') {
      formData['employeeId'] = inputEmployeeId
      setInputEmployeeIdValue('')
    }

    if (inputDepartment !== '') {
      formData['department'] = inputDepartment
      setInputDepartmentValue('')
    }

    // Get the authentication session
    const auth = await fetchAuthSession();
    const client = new S3Client({
      region: 'us-east-1',
      credentials: auth.credentials
    });
    try {
      // Write the item to the S3 bucket
      const putObjectCommand = new PutObjectCommand({
        Bucket: 'frontend-ingestion-bucket-vline', // Replace with your S3 bucket name
        Key: `${bucketKey}.json`, // Replace unique key generated above
        Body: JSON.stringify(formData) // Convert the formData object to a JSON string
      });

      const putObjectResponse = await client.send(putObjectCommand);
      setIsSubmitting(false); // Set isSubmitting back to false
      setShowSuccessFlashbar(true); // Show the flashbar
      console.log('Object uploaded to S3:', putObjectResponse);
    } catch (error) {
      setIsSubmitting(false); // Set isSubmitting back to false in case of an error
      setShowErrorFlashbar(true); // Show the error flashbar
      console.error('Error uploading object to S3:', error);
    }
  };

  return (
    <Router>
      <TopNavigation
        identity={{
          href: '/',
          title: 'HR Management System',
          logo: {
            src: logo,
            alt: 'logo'
          }
        }}
        utilities={[
          // {
          //   type: 'button',
          //   text: 'Go to IMS',
          //   href: 'https://ims.brucehow.people.aws.dev/',
          //   external: true,
          //   externalIconAriaLabel: ' (opens in a new tab)'
          // },
          {
            type: 'button',
            iconName: 'notification',
            title: 'Notifications',
            ariaLabel: 'Notifications (unread)',
            badge: true,
            disableUtilityCollapse: false
          },
          {
            type: 'menu-dropdown',
            text: 'User A',
            description: 'userA@example.com',
            iconName: 'user-profile',
            items: [
              { id: 'profile', text: 'Profile' },
              { id: 'preferences', text: 'Preferences' },
              { id: 'security', text: 'Security' },
              { id: 'signout', text: 'Sign out' }
            ]
          }
        ]}
      />
      <AppLayout
        toolsHide={true}
        navigation={
          <SideNavigation
            activeHref="/"
            items={[
              {
                type: 'section-group',
                title: 'HR',
                items: [
                  {
                    type: 'link',
                    text: 'Manage HR',
                    href: '/'
                  },
                  {
                    type: 'link',
                    text: 'Source Code',
                    external: true,
                    href: 'https://gitlab.aws.dev/aws-wwps-anz-proserve/vline/hr-payroll-integration'
                  }
                ]
              }
            ]}
          />
        }
        content={
          <ContentLayout>
            <Routes>
              <Route path="*" element={<Navigate replace to="/" />} />
              <Route
                path="/"
                element={
                  <SpaceBetween direction="vertical" size="m">
                    <BreadcrumbGroup
                      items={[
                        { text: 'HR', href: '/' },
                        { text: 'Manage HR', href: '/' }
                      ]}
                    />
                    <Header description="Effectively manage employees in the HR management system">
                      Manage HR Updates
                    </Header>
                    {showSuccessFlashbar && (
                      <Flashbar
                        items={[
                          {
                            type: 'success',
                            dismissible: true,
                            content: 'Employee has been successfully created',
                            onDismiss: () => setShowSuccessFlashbar(false)
                          },
                        ]}
                      />
                    )}
                    {showErrorFlashbar && (
                      <Flashbar
                        items={[
                          {
                            type: 'error',
                            dismissible: true,
                            content: 'An error occurred while processing the HR update',
                            onDismiss: () => setShowErrorFlashbar(false)
                          },
                        ]}
                      />
                    )}
                    {showSubmitFlashbar && (
                      <Flashbar
                        items={[
                          {
                            type: 'info',
                            dismissible: true,
                            content: 'Submitting your employee details...',
                            onDismiss: () => setShowSubmitFlashbar(false)
                          },
                        ]}
                      />
                    )}
                    <form onSubmit={handleSubmit}>
                      <Form
                        actions={
                          <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="primary" formAction="submit" disabled={isSubmitting}>
                              Submit
                            </Button>
                          </SpaceBetween>
                        }
                        header={<Header variant="h1">Add New Employee</Header>}
                      >
                        <Container>
                          <SpaceBetween direction="vertical" size="l">
                            <FormField label="First Name">
                              <Input
                                value={inputFirstName}
                                onChange={({ detail }) => setInputFirstNameValue(detail.value)}
                                placeholder="Enter your first name"
                              />
                            </FormField>
                            <FormField label="Last Name">
                              <Input
                                value={inputLastName}
                                onChange={({ detail }) => setInputLastNameValue(detail.value)}
                                placeholder="Enter your last name"
                              />
                            </FormField>
                            <FormField label="Employee ID">
                              <Input
                                value={inputEmployeeId}
                                onChange={({ detail }) => setInputEmployeeIdValue(detail.value)}
                                placeholder="Enter your employee ID"
                              />
                            </FormField>
                            <FormField label="Department">
                              <Input
                                value={inputDepartment}
                                onChange={({ detail }) => setInputDepartmentValue(detail.value)}
                                placeholder="Enter your department"
                              />
                            </FormField>
                          </SpaceBetween>
                        </Container>
                      </Form>
                    </form>
                  </SpaceBetween>
                }
              />
            </Routes>
          </ContentLayout>
        }
      />
    </Router>
  );
};

export default App;
